import React, { useState } from "react"
import { WidgetDisplay } from "./WidgetDisplay";
import { WidgetProps, BulkProductAllocationSettings } from "./DashboardContext";
import { isMultiValue, KaSelect } from "../Common/KaSelect";
import useGetBulkProductAllocation, { BulkProductAllocationDto } from "../Requests/useGetBulkProductAllocation";
import { BulkProductAllocationDisplay } from "./BulkProductAllocationDisplay";
import KaModal from "../Common/KaModal";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import { useUser } from "../UserAccess/useUser";
import useUserQuery from "../Common/useUserQuery";
import SiteDto from "../Common/dtos/SiteDto";
import ProductDto from "../Common/dtos/ProductDto";
import useUpdateBulkProductAllocationWidgetSettings from "../../requests/useUpdateBulkProductAllocationWidgetSettings";
import KaAlert from "../Common/KaAlert";
import useDisplayMessage from "../Common/useDisplayMessage";

export const units = ["Pound", "Gallon", "Ton"];

type BulkProductAllocationProps = WidgetProps & BulkProductAllocationSettings;

export const BulkProductAllocation = (props: BulkProductAllocationProps) => {
  const user = useUser();
  const business = user.selectedBusiness;
  const [unit, setUnit] = useState<string>(!!props.selectedUnit ? props.selectedUnit : units[0]);
  const [site, setSite] = useState<string>(!!props.selectedSiteId ? props.selectedSiteId : 'AllSites');
  const [products, setProducts] = useState<string[] | undefined>(props.selectedProductIds);
  const [name, setName] = useState<string>(props.name === "" ? "Bulk Product Allocation" : props.name);
  const [data, setData] = useState<BulkProductAllocationDto[]>();

  const getSites = useUserQuery<SiteDto[]>({ path: `/api/businesses/${business?.id}/sites` });
  const getProducts = useUserQuery<ProductDto[]>({ path: `/api/businesses/${business?.id}/products` });

  const getBulkProductAllocation = useGetBulkProductAllocation({
    unit: unit ?? units[0],
    siteId: site,
    products: products,
  }, {
    onSuccess: (resp) => {
      setData(resp.bulkProductAllocations);
    }
  });

  const Settings = (show: boolean, onClose: () => void) => {
    const displayMessage = useDisplayMessage();
    const [modifiedName, setModifiedName] = useState(name);
    const [selectedUnit, setSelectedUnit] = useState<string>(unit);
    const [selectedSite, setSelectedSite] = useState<string>(site ?? 'AllSites');
    const [selectedProducts, setSelectedProducts] = useState<string[]>(products ?? ['', '', '', '', '']);

    const updateSettings = useUpdateBulkProductAllocationWidgetSettings(props.id.toString(), {
      onSuccess: () => {
        setName(modifiedName);
        setUnit(selectedUnit);
        setSite(selectedSite);
        setProducts(selectedProducts);
        displayMessage.clear();
        onClose();
      },
      onError: (err) => displayMessage.fail(err.message)
    });

    if (getSites.isLoading || getProducts.isLoading) return <KaModal
      onHide={onClose}
      show={show}
      title={'Bulk Product Allocation Settings'}
      body={(<div className="mt-3">
        <CenteredSpinner />
      </div>)}
    />

    if (getSites.isError || getProducts.isError) return <KaModal
      onHide={onClose}
      show={show}
      title={'Bulk Product Allocation Settings'}
      body={(<div className="mt-3">
        <h3>{getSites.error?.message ?? getProducts.error?.message}</h3>
      </div>)}
    />

    const unitOption = (unit: string) => ({ value: unit, label: unit + "s" });

    const sortedSites = getSites.data?.sort((a, b) => a.name.localeCompare(b.name));
    const sortedSiteOptions = sortedSites?.map(s => ({ value: s.id, label: s.name })) ?? [];
    const AggregateSite = { value: 'AllSites', label: "All Sites" };
    const siteOptions = [AggregateSite].concat(sortedSiteOptions);

    const sortedProducts = getProducts.data?.sort((a, b) => a.name.localeCompare(b.name));
    const productOptions = sortedProducts?.map(p => ({ value: p.id, label: p.name })) ?? [];

    const saveSettings = () =>
      updateSettings.request({
        name: modifiedName,
        selectedProductIds: selectedProducts.filter((product) => product !== ''),
        selectedSiteId: selectedSite === 'AllSites' ? undefined : selectedSite,
        selectedUnit: selectedUnit
      });

    const updateProducts = (index: number, id: string) => {
      const products = Object.assign<string[], string[]>([], selectedProducts);
      products[index] = id;
      setSelectedProducts(products);
    }

    return (<KaModal
      onHide={onClose}
      show={show}
      title={'Bulk Product Allocation Settings'}
      body={(<>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <div className="row mb-3">
          <h5 className="w-100 d-flex justify-content-center">
            The bulk product allocation widget displays allocated quantities for open orders versus on-hand inventory at a site.
          </h5>
        </div>
        <h5>Widget Name:</h5>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Widget Name"
          value={modifiedName}
          onChange={(e) => setModifiedName(e.target.value)} />
        <div className="row mt-3">
          <div className="col">
            <h5>Site:</h5>
            <KaSelect
              placeholder="Select Site"
              options={siteOptions}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedSite(valueObject?.value!); }
              }}
              defaultValue={siteOptions.find((option) => option.value === site!)}
            />
          </div>
          <div className="col">
            <h5>Unit:</h5>
            <KaSelect
              placeholder="Select Unit"
              options={units.map(u => unitOption(u))}
              onChange={(valueObject) => {
                if (!isMultiValue(valueObject)) { setSelectedUnit(valueObject?.value!); }
              }}
              defaultValue={unitOption(unit)} />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="w-75">
            <h5>Select up to 5 Products:</h5>
            {Array.from({ length: 5 }, (_, i) => (
              <KaSelect
                key={i}
                placeholder="Select Product"
                isClearable={true}
                options={productOptions}
                defaultValue={products ? productOptions.find(o => o.value === selectedProducts[i]) : undefined}
                onChange={(valueObject) => {
                  if (!isMultiValue(valueObject)) { updateProducts(i, valueObject?.value ?? ''); }
                }}
                className={i === 4 ? "" : "pb-3"} />
            ))}
          </div>
        </div>
      </>)}
      footer={(
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSettings()}>
          Save
        </button>)}
    />)
  }

  var errorMessage = "";

  if (getSites.isError) { errorMessage = getSites.error!.message; }
  else if (getProducts.isError) { errorMessage = getProducts.error!.message; } 
  else if (getBulkProductAllocation.isError) { errorMessage = getBulkProductAllocation.error!.message; }
  
  return <WidgetDisplay
    widgetId={props.id}
    title={name}
    path='/bulkProductAllocation'
    isLoading={data === undefined && getBulkProductAllocation.isLoading}
    settings={Settings}>
    {data?.length === 0 ?
      <div className="d-flex justify-content-center">
        <h2 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5 text-wrap" style={{ color: 'darkslategrey' }}>Please select products to view inventory levels</h2>
      </div>
      : <>
        <div className="h-100 w-100">
          {data
            ? <BulkProductAllocationDisplay data={data!.slice(0, 5)} unit={unit.concat('s')} />
            : <div className="col h-100 d-flex align-items-center" style={{ color: 'rgba(237, 71, 52)' }}>
              <div className="w-100 mx-4">
                <div className="row text-center">
                  <i className="bi bi-x-circle-fill fs-1"></i>
                </div>
                <div className="row text-center fs-5">
                  {errorMessage}
                </div>
              </div>
            </div>}
        </div>
      </>
    }
  </WidgetDisplay>
}
