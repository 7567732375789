import React, { useEffect, useState } from 'react';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import { useUser } from '../UserAccess/useUser';
import SiteDto from '../Common/dtos/SiteDto';
import { ProductLoadedDisplay } from '../Dashboard/ProductLoadedDisplay';
import { NavLink } from 'react-router-dom';
import SelectSearch, { SelectOption } from '../Common/Forms/SelectSearch';
import { units } from '../Dashboard/ProductLoadedWidget';
import useLocalStorage from '../Common/useLocalStorage';
import useGetProductLoaded, { ProductLoadedResponse } from '../Requests/useGetProductLoaded';
import ProductDto from '../Common/dtos/ProductDto';
import useInterval from '../Common/useInterval';

const getSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/sites`,
});

const getProductsSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/products`,
});

const ProductLoadedPage = () => {
  const user = useUser();
  const business = user.selectedBusiness;
  const getSites = useUserQuery<SiteDto[]>(getSpec(business?.id), { autoQuery: business?.administrator });
  const getProducts = useUserQuery<ProductDto[]>(getProductsSpec(business?.id), { autoQuery: business?.administrator });
  const product = useLocalStorage<string | undefined>("previousProduct", undefined)[0];
  const unit = useLocalStorage<string | undefined>("previousUnit", undefined)[0];

  if (getSites.isLoading || getProducts.isLoading) return <CenteredSpinner />
  if (getSites.isError) return <h3>{getSites.error?.message}</h3>
  if (getProducts.isError) return <h3>{getProducts.error?.message}</h3>

  const sortedSites = getSites.data?.sort((a, b) => a.name.localeCompare(b.name));
  const sortedProducts = getProducts.data?.sort((a, b) => a.name.localeCompare(b.name));

  if (sortedSites?.length === 0) return <h3>No sites found</h3>
  if (sortedProducts?.length === 0) return <h3>No products found</h3>

  return <Page
    sites={sortedSites!.concat([AggregateSite])}
    products={sortedProducts!}
    initialProduct={product}
    initialUnit={unit} />;
}

type PageProps = {
  sites: Site[];
  products: ProductDto[];
  initialProduct?: string;
  initialUnit?: string;
}

type Site = {
  id?: string;
  name: string;
}

const AggregateId = '4bc2ad8f-59a7-4459-843a-2ad36929b561';
const AggregateSite: SiteDto = { id: AggregateId, name: "Aggregate", isRegistered: false };

const Page = ({ sites, products, initialProduct, initialUnit }: PageProps) => {
  const [product, setProduct] = useState<string>(products.find((p) => p.name === initialProduct)?.id ?? products[0].id);
  const [unit, setUnit] = useState<string>(initialUnit ?? units[0]);
  const [site1, setSite1] = useState<Site>({ id: sites[0].id, name: sites[0].name });
  const [site2, setSite2] = useState<Site>(AggregateSite);
  const timeZoneOffset = new Date().getTimezoneOffset();
  const refreshTimeMs = 30000;
  const [values, setValues] = useState<ProductLoadedResponse | null>();
  const [values2, setValues2] = useState<ProductLoadedResponse | null>();
  const [startingTimes, setStartingTimes] = useState<{
    hour: string | undefined,
    day: string | undefined,
    week: string | undefined,
    month: string | undefined
  } | undefined>();

  const getSite1ProductLoaded = useGetProductLoaded({
    unit: unit,
    timeZoneOffset: timeZoneOffset,
    siteId: site1.id,
    product: product,
  }, {
    onSuccess: (data) => {
      setStartingTimes({
        hour: data.startOfHour,
        day: data.startOfDay,
        week: data.startOfWeek,
        month: data.startOfMonth,
      });
    }
  });

  const getSite2ProductLoaded = useGetProductLoaded({
    unit: unit,
    timeZoneOffset: timeZoneOffset,
    siteId: site2.id === AggregateId ? undefined : site2.id,
    product: product,
  });

  useInterval(() => {
    getSite1ProductLoaded.query();
    getSite2ProductLoaded.query();
  }, refreshTimeMs, false);

  useEffect(() => {
    if (getSite1ProductLoaded.isLoading || getSite2ProductLoaded.isLoading) return;

    setValues(getSite1ProductLoaded.data);
    setValues2(getSite2ProductLoaded.data); 

  }, [getSite1ProductLoaded, getSite2ProductLoaded, products, product, values, values2]);

  const sortedSiteOptions = sites
    .sort((a, b) => a.name.localeCompare(b.name))
    .map<SelectOption>(s => ({ value: s.id ?? '', name: s.name }));

  return (<>
    <div className="ms-n2 pb-3 d-flex justify-content-between">
      <NavLink className="card-link my-auto text-nowrap" to="/" style={{ fontSize: 20 }}>
        <i className="bi-chevron-left" />
        Product Loaded
      </NavLink>
      <div className="col-2">
        Site 1:
        <SelectSearch
          id="site1"
          selectedValue={site1.id}
          onChange={(site) => { setSite1(sites.find(s => s.id === site) ?? AggregateSite) }}
          options={sortedSiteOptions.filter(s => s.value !== site2.id && s.value !== AggregateId)} />
      </div>
      <div className="col-2">
        Site 2:
        <SelectSearch
          id="site2"
          selectedValue={site2.id}
          onChange={(site) => { setSite2(sites.find(s => s.id === site) ?? AggregateSite) }}
          options={sortedSiteOptions.filter(s => s.value !== site1.id)} />
      </div>
      <div className="col-3">
        Product:
        <SelectSearch
          id="products"
          selectedValue={product}
          onChange={(productId) => { setProduct(productId) }}
          options={products.map<SelectOption>(p => ({ value: p.id, name: p.name }))} />
      </div>
      <div className="col-2">
        Unit:
        <SelectSearch
          id="unit"
          selectedValue={unit}
          hideSearch={true}
          onChange={(unit) => { setUnit(unit) }}
          options={units.map<SelectOption>(u => ({ value: u, name: u + "s" }))} />
      </div>
    </div>
    <div className="w-100 vh-50 d-flex justify-content-between">
      <div className="w-25 h-100 px-2">
        <ProductLoadedDisplay
          siteNames={[site1.name, site2.name]}
          siteAmounts={[values?.loadedAmountHour ?? 0, values2?.loadedAmountHour ?? 0]}
          timeframe="Hourly"
          startingAt={startingTimes?.hour ?? ""}
        />
      </div>
      <div className="w-25 h-100 px-2">
        <ProductLoadedDisplay
          siteNames={[site1.name, site2.name]}
          siteAmounts={[values?.loadedAmountDay ?? 0, values2?.loadedAmountDay ?? 0]}
          timeframe="Today"
          startingAt={startingTimes?.day ?? ""}
        />
      </div>
      <div className="w-25 h-100 px-2">
        <ProductLoadedDisplay
          siteNames={[site1.name, site2.name]}
          siteAmounts={[values?.loadedAmountWeek ?? 0, values2?.loadedAmountWeek ?? 0]}
          timeframe="Week"
          startingAt={startingTimes?.week ?? ""}
        />
      </div>
      <div className="w-25 h-100 px-2">
        <ProductLoadedDisplay
          siteNames={[site1.name, site2.name]}
          siteAmounts={[values?.loadedAmountMonth ?? 0, values2?.loadedAmountMonth ?? 0]}
          timeframe="Month"
          startingAt={startingTimes?.month ?? ""}
        />
      </div>
    </div>
  </>);
}

export { ProductLoadedPage };